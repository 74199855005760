import React, { useState } from "react";
import "./gallery.css";
import studio1 from "../../media/images/studio/studio1.jpg";
import studio2 from "../../media/images/studio/studio2.jpg";
import studio2_more from "../../media/images/studio/studio2-more.jpg";
import studio3 from "../../media/images/studio/studio3.jpg";
import studio4 from "../../media/images/studio/studio4.jpg";
import studio5 from "../../media/images/studio/studio5.jpg";

import product1 from "../../media/images/product/product1.jpg";
import product2 from "../../media/images/product/product2.jpg";
import product3 from "../../media/images/product/product3.jpg";
import product4 from "../../media/images/product/product4.jpg";
import product5 from "../../media/images/product/product5.jpg";
import product6 from "../../media/images/product/product6.jpg";
import product7 from "../../media/images/product/product7.jpg";

import sadnaVideo from "../../media/images/sadnaVideo.mp4";
import sadnaVideo2 from "../../media/images/sadnaVideo2.mp4";

const Gallery = () => {
    const [isStop, setIsStop] = useState(false);

    const [studio, setStudio] = useState([
        studio1,
        studio2,
        studio2_more,
        studio3,
        studio4,
        studio5,
    ]);
    const [product, setProduct] = useState([
        product1,
        product2,
        product3,
        product4,
        product5,
        product6,
        product7,
    ]);
    return (
        <div id="gallery" data-scroll={!isStop}>
            <h1>גלריה</h1>
            <div className="isStopDiv">
                <button
                    aria-label="Toggle stop sliding photos"
                    onClick={() => {
                        setIsStop(!isStop);
                    }}
                >
                    {isStop ? (
                        <i className="bi bi-play-fill"></i>
                    ) : (
                        <i className="bi bi-pause-fill"></i>
                    )}
                </button>
            </div>
            <div>
                <div>
                    <h2>סטודיו</h2>
                    <ul>
                        {studio && (
                            <>
                                {studio.map((i) => (
                                    <li key={i}>
                                        <img src={i} alt="My studio" />
                                    </li>
                                ))}
                                {!isStop &&
                                    studio.map((i) => (
                                        <li key={i}>
                                            <img src={i} alt="My studio" />
                                        </li>
                                    ))}
                            </>
                        )}
                    </ul>
                </div>
                <div>
                    <h2>תוצרים</h2>
                    <ul>
                        {product && (
                            <>
                                {product.map((i) => (
                                    <li>
                                        <img src={i} alt="My product" />
                                    </li>
                                ))}
                                {!isStop &&
                                    product.map((i) => (
                                        <li>
                                            <img src={i} alt="My product" />
                                        </li>
                                    ))}
                            </>
                        )}
                    </ul>
                </div>
                <div>
                    <h2>סרטונים</h2>
                    <div>
                        <video src={sadnaVideo} controls></video>
                        <video src={sadnaVideo2} controls></video>
                    </div>
                </div>
            </div>
            <button id="contactBtn">
                <a href="#contact">אני רוצה סדנה!</a>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-gem"
                    viewBox="0 0 16 16"
                >
                    <path d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6zm11.386 3.785-1.806-2.41-.776 2.413zm-3.633.004.961-2.989H4.186l.963 2.995zM5.47 5.495 8 13.366l2.532-7.876zm-1.371-.999-.78-2.422-1.818 2.425zM1.499 5.5l5.113 6.817-2.192-6.82zm7.889 6.817 5.123-6.83-2.928.002z" />
                </svg>
            </button>
        </div>
    );
};

export default Gallery;
