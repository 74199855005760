import { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import necessary components

import AOS from "aos";
import "aos/dist/aos.css";
import Home from "./component/Home";
import Accessibility from "./component/accessibility/Accessibility";

function App() {
    useEffect(() => {
        AOS.init({
            duration: 1500,
            offset: 120,
        });
    }, []);
    return (
        <Router>
            <div
                className="App"
                style={{
                    "--highlight_fade": "rgba(161, 117, 92, 0.5)",
                    "--highlight": "#A1755C",
                    "--weak": "#fff",
                    "--strong": "#000",
                }}
            >
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                        path="/accessibility-statement"
                        element={<Accessibility />}
                    />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
