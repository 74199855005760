import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import SmallScreenHeader from "./SmallScreenHeader";

const Header = () => {
    const [displayNav, setDisplayNav] = useState(false);
    const [visible, setVisible] = useState(true);
    const [prevY, setPrevY] = useState(window.scrollY);
    const header = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const currentY = window.scrollY;
            if (prevY > currentY && prevY - currentY > 5) {
                setVisible(true);
            } else if (currentY > prevY && currentY - prevY > 5) {
                setVisible(false);
            }
            setPrevY(currentY);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevY]);

    useEffect(() => {
        if (header.current) {
            header.current.style.transition = "transform 0.3s";
            header.current.style.transform = visible
                ? "translateY(0)"
                : "translateY(-120px)";
        }
    }, [visible]);

    return (
        <header ref={header} className="big_screen">
            <button
                onClick={() => {
                    setDisplayNav(true);
                }}
            >
                תפריט
            </button>
            <nav>
                <ul>
                    <li>
                        <div className="paste-button">
                            <button className="button" aria-label="More menu">
                                עוד <span>&nbsp;▼</span>
                            </button>
                            <div className="dropdown-content">
                                <a id="top" href="#about">
                                    מי אני?
                                </a>
                                <a id="middle" href="#whyMe">
                                    למה סדנה?
                                </a>
                                <a id="middle" href="#services">
                                    שירותים
                                </a>
                                <a id="middle" href="#recommendations">
                                    לקוחות מרוצים
                                </a>
                                <a id="middle" href="#gallery">
                                    גלריה
                                </a>
                                <a id="middle" href="#Sales">
                                    שוברים
                                </a>
                                <a id="bottom" href="#faq">
                                    שאלות נפוצות
                                </a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="services">שירותים</a>
                    </li>
                    <li>
                        <a href="#contact">צור קשר</a>
                    </li>
                    <li>
                        <a href="#">בית</a>
                    </li>
                </ul>
            </nav>
            <h1>NAVA BACHAR</h1>
            {displayNav && (
                <SmallScreenHeader
                    setDisplayNav={setDisplayNav}
                    displayNav={displayNav}
                />
            )}
        </header>
    );
};

export default Header;
