import React, { useEffect, useState } from "react";
import "./faq.css";

const faqData = [
    {
        key: "one",
        question: "היכן מתקיימת הסדנה?",
        answer: "בסטודיו ביתי, נעים ומאובזר בעושר. בארוח ביתי וחם עם קפה ועוגה שקוצרת מחמאות ומזוהה רק איתי…",
    },
    {
        key: "two",
        question: "למי מתאימה הסדנה?",
        answer: "לכל אחד מגיל 8 ומעלה אתאים את עצמי אנחה ואעזור.",
    },
    {
        key: "three",
        question: "מה נעשה בסדנה?",
        answer: "נקבל הסבר ורקע על עולם החומר, כסף וזהב, נילמד על הכלים המשמשים את הצורף, נכיר סוגי תכשיטים ותוכלו להתרשם מכל מיני רעיונות לתכשיטים שמצויים אצלי בסטודיו., נתכנן, נשרטט , נתיך , ננסר, ניפצור, נרקע,ננקב, נלטש והכי חשוב נהנה. משך הסדנה: שלוש שעות מה כוללת הסדנה? כסף טהור במישקל של עד 10 גרם טהור, כלי עבודה, חומרי עבודה, ביטוח, שתיה וכיבוד. * ניתן לעבוד גם בזהב בעלות מחיר הזהב שמקובל בשוק.",
    },
    {
        key: "four",
        question: "מה ניתן להכין בסדנה?",
        answer: "תליונים, צמידים, סיכות דש, מחזיקי מפתחות וכל תכשיט אחר שיעלה על דעתכם.אפשר להגיע עם רעיון מגובש ואפשר לבוא עם קצה של רעיון שיושלם תוך כדי עבודה, ואפשר גם שנגיע לרעיון ביחד.",
    },
];

const Faq = () => {
    const [openFaqs, setOpenFaqs] = useState({});

    const handleToggleFaq = (key) => {
        setOpenFaqs((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    return (
        <div className="faqs" id="faq">
            <div>
                <h1 data-aos="fade-right">שאלות?</h1>
            </div>
            <ul data-aos="fade-left">
                {faqData.map(({ key, question, answer }) => (
                    <li key={key} className={openFaqs[key] ? "expanded" : ""}>
                        <div>
                            <h2 style={{ direction: "rtl" }}>{question}</h2>
                            <button aria-label="Toggle show answer">
                                <label className="hamburger">
                                    <input
                                        onClick={() => handleToggleFaq(key)}
                                        type="checkbox"
                                    />
                                    <svg viewBox="0 0 32 32">
                                        <path
                                            className="line line-top-bottom"
                                            d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
                                        ></path>
                                        <path
                                            className="line"
                                            d="M7 16 27 16"
                                        ></path>
                                    </svg>
                                </label>
                            </button>
                        </div>
                        <p style={{ direction: "rtl" }}>{answer}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Faq;
