import React from "react";
import "./accessibility.css";

const Accessibility = () => {
    return (
        <div id="accessibility">
            <h1>הצהרת נגישות של Nava Jewelry</h1>
            עדכון: דצמבר 2024.
            <br />
            <h2>כללי</h2>
            Nava Jewelry פועלת להבטיח ששירותיה יהיו נגישים לאנשים עם מוגבלויות.
            Nava Jewelry השקיעה משאבים רבים כדי להבטיח שהאתר שלה יהיה קל יותר
            לשימוש ונגיש יותר עבור אנשים עם מוגבלויות, מתוך אמונה חזקה ש{" "}
            <a rel="nofollow" href="https://userway.org">
                נגישות אתרים
            </a>{" "}
            מסייעת לכל המשתמשים ושהזכות של כל אדם היא לחיות בכבוד, שוויון, נוחות
            ועצמאות.
            <h2>נגישות ב-navabachar.com</h2>
            אתר navabachar.com מציע את{" "}
            <a
                rel="nofollow"
                href="https://userway.org"
                alt="ווידג'ט נגישות אתרים חינם"
                title="ווידג'ט נגישות אתרים חינם"
            >
                ווידג'ט נגישות אתרים של UserWay
            </a>{" "}
            המופעל על ידי שרת נגישות ייעודי. התוכנה מאפשרת לאתר navabachar.com
            לשפר את התאמתו להנחיות נגישות התוכן של האינטרנט (WCAG 2.1).
            <h2>הפעלת תפריט הנגישות</h2>
            תפריט הנגישות של navabachar.com ניתן להפעיל על ידי לחיצה על אייקון
            תפריט הנגישות המופיע בפינה של הדף. לאחר הפעלת תפריט הנגישות, יש
            להמתין כמה שניות עד שהתפריט יטען במלואו.
            <h2>הודעת שחרור</h2>
            Nava Jewelry ממשיכה במאמצים שלה לשפר כל הזמן את נגישות האתר
            והשירותים שלה מתוך אמונה כי זוהי חובתנו המוסרית המשותפת לאפשר שימוש
            חלק, נגיש וללא הפרעות גם עבור אנשים עם מוגבלויות.
            <br />
            <br />
            במאמץ מתמשך לשיפור מתמיד ולתיקון בעיות נגישות, אנו סורקים באופן קבוע
            את אתר navabachar.com בעזרת{" "}
            <a
                rel="nofollow"
                href="https://UserWay.org/scanner"
                title="סורק נגישות אתרים חינם"
            >
                סורק נגישות של UserWay
            </a>{" "}
            כדי לזהות ולתקן כל מכשול נגישות אפשרי באתר שלנו. למרות המאמצים שלנו
            להבטיח שכל הדפים והתכנים ב-navabachar.com יהיו fully נגישים, ייתכן
            שחלק מהתכנים לא הותאמו עדיין לכל הסטנדרטים הנגישות הקפדניים ביותר.
            ייתכן שזה נובע מכך שלא מצאנו או לא זיהינו את הפתרון הטכנולוגי המתאים
            ביותר.
            <h2>כאן בשבילך</h2>
            אם אתה נתקל בקשיים עם כל תוכן ב-navabachar.com או אם אתה זקוק לעזרה
            בחלק כלשהו מהאתר שלנו, אנא פנה אלינו בשעות העבודה הרגילות כפי שמפורט
            למטה ואנחנו נשמח לעזור.
            <h2>צור קשר</h2>
            אם ברצונך לדווח על בעיית נגישות, יש לך שאלות או שאתה זקוק לעזרה, אנא
            פנה לשירות הלקוחות של Nava Jewelry כפי שמפורט להלן:
            <br />
            <br />
            דוא"ל:
            <a rel="nofollow" href="mailto:accessibility@navabachar.com">
                accessibility@navabachar.com
            </a>
            טלפון: +972 050 647 9772
            <button id="back" aria-label="Back to main website">
                <a href="/">חזרה לדף הראשי</a>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-gem"
                    viewBox="0 0 16 16"
                >
                    <path d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6zm11.386 3.785-1.806-2.41-.776 2.413zm-3.633.004.961-2.989H4.186l.963 2.995zM5.47 5.495 8 13.366l2.532-7.876zm-1.371-.999-.78-2.422-1.818 2.425zM1.499 5.5l5.113 6.817-2.192-6.82zm7.889 6.817 5.123-6.83-2.928.002z" />
                </svg>
            </button>
        </div>
    );
};

export default Accessibility;
