import React from "react";
import "./sales.css";

import sale1 from "../../media/images/sale1.jpg";
import sale2 from "../../media/images/sale2.jpg";
import sale3 from "../../media/images/sale3.jpg";

const Sales = () => {
    return (
        <div id="Sales">
            <h1 data-aos="fade-up">שוברים</h1>
            <ul>
                <li data-aos="fade-down-right">
                    <img src={sale2} alt="Coupon" />
                </li>
                <li data-aos="fade-down">
                    <img src={sale3} alt="Coupon" />
                </li>
                <li data-aos="fade-down-left">
                    <img src={sale1} alt="Coupon" />
                </li>
            </ul>
        </div>
    );
};

export default Sales;
