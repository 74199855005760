import React from "react";
import "./footer.css";
const Footer = () => {
    return (
        <footer>
            <div>
                <h1>האתר עוצב ונבנה על ידי: </h1>
                <span>
                    <h2>daniel@daniel-allali.com</h2>
                    <h2>daniel.allali12@gmail.com</h2>
                    <a href="https://daniel-allali.com" target="_blank">
                        ליצירת קשר
                    </a>
                </span>
            </div>
            <div>
                <h1>Nava Bachar</h1>
                <a href="/accessibility-statement">
                    הצהרת נגישות<i class="bi bi-universal-access"></i>
                </a>
                <hr />
                <h2>&copy; All rights reserved.</h2>
            </div>
        </footer>
    );
};

export default Footer;
