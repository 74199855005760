import React from "react";
import Header from "./header/Header";
import OpenSection from "./openSection/OpenSection";
import About from "./about/About";
import WhySadna from "./whySadna/WhySadna";
import Services from "./services/Services";
import Recommendations from "./recommendations/Recommendations";
import Faq from "./faq/Faq";
import Contact from "./contact/Contact";
import Footer from "./footer/Footer";
import Gallery from "./gallery/Gallery";
import Sales from "./sales/Sales";
const Home = () => {
    return (
        <div>
            <Header />
            <OpenSection />
            <About />
            <WhySadna />
            <Services />
            <Recommendations />
            <Gallery />
            <Sales />
            <Faq />
            <Contact />
            <Footer />
        </div>
    );
};

export default Home;
