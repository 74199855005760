import React, { useState } from "react";
import "./header.css";
const SmallScreenHeader = ({ setDisplayNav, displayNav }) => {
    const [display, setDisplay] = useState(displayNav);
    return (
        <div
            id="small_screen"
            onClick={() => {
                setDisplay(false);
                setTimeout(() => {
                    setDisplayNav(false);
                }, 200);
            }}
        >
            <nav className={display ? "active" : "close"}>
                <button
                    onClick={() => {
                        setDisplay(false);
                        setTimeout(() => {
                            setDisplayNav(false);
                        }, 200);
                    }}
                >
                    <i class="bi bi-x-lg"></i>
                </button>
                <ul>
                    <li>
                        <a href="#">בית</a>
                    </li>
                    <li>
                        <a href="#services">שירותים</a>
                    </li>
                    <li>
                        <a href="#contact">צור קשר</a>
                    </li>
                    <li>
                        <a href="#about">מי אני?</a>
                    </li>
                    <li>
                        <a href="#whyMe">למה סדנה?</a>
                    </li>
                    <li>
                        <a href="#recommendations">לקוחות מרוצים</a>
                    </li>
                    <li>
                        <a href="#gallery">גלריה</a>
                    </li>
                    <li>
                        <a href="#Sales">שוברים</a>
                    </li>
                    <li>
                        <a href="#faq">שאלות נפוצות</a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default SmallScreenHeader;
