import React from "react";
import "./services.css";

const Services = () => {
    return (
        <div id="services">
            <h1 data-aos="fade-up">שירותים</h1>
            <div>
                <div data-aos="fade-left">
                    <h1>🚀🛠️</h1>
                    <h2>סדנאות</h2>
                    <p>סדנאות פרטיות זוגות, שלישיות ורביעיות</p>
                </div>
                <div data-aos="fade-right">
                    <h1>💍💎</h1>
                    <h2>תכשיטים</h2>
                    <p>טבעות נישואים טבעות וכו...</p>
                </div>
                <div data-aos="fade-left">
                    <h1>📖🔖</h1>
                    <h2>סימניות לספר</h2>
                    <p>
                        סימניות מותאמות אישית בהשראת יצירות צורפות – חוויה
                        ייחודית ופרקטית.
                    </p>
                </div>
                <div data-aos="fade-right">
                    <h1>💎✨✍️</h1>
                    <h2>תכשיטים בהזמנה אישית</h2>
                    <p>
                        עיצוב אישי ומדויק של תכשיטים בהתאמה לסגנון ולצרכים שלך –
                        טבעות, צמידים, שרשראות ועוד.
                    </p>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="To shop"
                        href="http://navajewelry.co.il"
                    >
                        לחנות
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Services;
