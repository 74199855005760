import React, { useState } from "react";
import "./recommendations.css";
import fiveStars_image from "../../media/images/fiveStars.png";
import image1 from "../../media/images/image1.jpg";
import image2 from "../../media/images/image2.jpg";
import image3 from "../../media/images/image3.jpg";
import image4 from "../../media/images/image4.jpg";
import image5 from "../../media/images/image5.jpg";
import image6 from "../../media/images/image6.jpg";
import image7 from "../../media/images/image7.jpg";
import image8 from "../../media/images/image8.jpg";
import image9 from "../../media/images/image9.jpg";
import image10 from "../../media/images/image10.jpg";
import image11 from "../../media/images/image11.jpg";
import image12 from "../../media/images/image12.jpg";

const Recommendations = () => {
    const [recommendations, setRecommendations] = useState([
        {
            name: "שרית",
            text: "אמא שלי ואני הגענו אל נאוה לבוקר של זמן איכות מרגש. אמא שלי התיכה צמיד זהב ישן ועשתה לעצמה 2 צמידים: האחד כסף והשני זהב. אני התכתי טבעת נישואין ישנה ויצרתי ממנה משהו שמתאים למי ולמה שאני היום. נאוה המקסימה ליוותה במקצועיות, סבלנות וחיוך את התהליך. וכל רגע היה עונג.",
        },
        {
            name: "אפרת שקוף",
            text: "נאוה נהדרת, זרמה והבינה את הרצונות והצרכים, היתה סבלנית ונעימה, עשינו טבעות מהממות!!! יצאנו מאווווד מרוצות:)",
        },
        {
            name: "דנה",
            text: "סדנא מושלמת! תודה על סדנה מעולה בה בתי ואני יצרנו תכשיטים - עזרו לנו להפוך תמונת תכשיט למציאות. עם המון סבלנות וחיוך - תענוג!!!",
        },
        {
            name: "יונה",
            text: "הגענו 4 בנות משפחה לסדנה. קיבלנו יחס נעים וסבלני מנאוה, הכווינה אך גם זרמה עם הרעיונות שלנו :) יצאנו מרוצות עם צמידים יפים. תודה רבה",
        },
        {
            name: "ארית",
            text: "באתי עם שלוש בנותיי, בגילאי 11-19 לסדנה, כל אחת יצאה עם תכשיט מקורי משלה, גם למדנו הרבה טכניקות חדשות וכלים חדשים והתנסינו בהם בעצמנו, וגם התרשמנו מאד מנאוה הנעימה, הסבלנית והמקצוענית. לימדה והדריכה אבל נתנה הרבה חופש, ועוד כיבדה אותנו בגלידות (-: ממליצות מאד!",
        },
        {
            name: "מתן",
            text: "חוויה זוגית מיוחדת ומגניבה, נאוה מקצועית מאוד ולבבית, ממליץ",
        },
    ]);
    const [images, setImages] = useState([
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
        image7,
        image8,
        image9,
        image10,
        image11,
        image12,
    ]);
    const [isStop, setIsStop] = useState(false);
    return (
        <div id="recommendations" data-scroll={!isStop}>
            <h1 data-aos="fade-up">לקוחות מרוצים</h1>
            <div>
                <button
                    onClick={() => {
                        setIsStop(!isStop);
                    }}
                >
                    {isStop ? (
                        <i className="bi bi-play-fill"></i>
                    ) : (
                        <i className="bi bi-pause-fill"></i>
                    )}
                </button>
            </div>
            <div>
                <ul>
                    {recommendations && (
                        <>
                            {recommendations.map((r) => (
                                <li key={r}>
                                    <h1>{r.name}</h1>
                                    <img
                                        src={fiveStars_image}
                                        alt="Five stars review"
                                    />
                                    <p>{r.text}</p>
                                </li>
                            ))}
                            {recommendations.map((r) => (
                                <li key={r}>
                                    <h1>{r.name}</h1>
                                    <img
                                        src={fiveStars_image}
                                        alt="Five stars review"
                                    />
                                    <p>{r.text}</p>
                                </li>
                            ))}
                        </>
                    )}
                </ul>
                <ul>
                    {images && (
                        <>
                            {images.map((i) => (
                                <li
                                    key={i}
                                    aria-label="Image of: Customers, Studio, Examples of jewelry"
                                >
                                    <img
                                        src={i}
                                        alt="Customers, Studio, Examples of jewelry"
                                    />
                                </li>
                            ))}
                            {images.map((i) => (
                                <li key={i}>
                                    <img
                                        src={i}
                                        alt="Customers, Studio, Examples of jewelry"
                                    />
                                </li>
                            ))}
                        </>
                    )}
                </ul>
            </div>
            <button id="contactBtn">
                <a href="#contact">אני רוצה כמוהם!</a>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-gem"
                    viewBox="0 0 16 16"
                >
                    <path d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6zm11.386 3.785-1.806-2.41-.776 2.413zm-3.633.004.961-2.989H4.186l.963 2.995zM5.47 5.495 8 13.366l2.532-7.876zm-1.371-.999-.78-2.422-1.818 2.425zM1.499 5.5l5.113 6.817-2.192-6.82zm7.889 6.817 5.123-6.83-2.928.002z" />
                </svg>
            </button>
        </div>
    );
};

export default Recommendations;
